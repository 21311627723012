<template>
  <div class="section notopmargin nopadding nobg home-stepfundraiser-sec">
    <div class="container">
      <div class="heading-block center">
        <h2>3 Easy Steps</h2>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="fundraiser-step-wrap">
            <div class="fundstep-item">
              <img
                src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117711/web/ic-start-fundraiser.svg"
                class="fundstep-icon"
              />
              <div class="fundstep-text">
                <h3>Create your fundraiser</h3>
                <p>
                  It'll take only 2 minutes. Just tell us a few details about
                  you and what you are raising funds for.
                </p>
              </div>
            </div>
            <div class="fundstep-item">
              <img
                src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117710/web/ic-share-fundraiser.svg"
                class="fundstep-icon"
              />
              <div class="fundstep-text">
                <h3>Share your fundraiser with your supporters</h3>
                <p>
                  All you need to do is consistently share the fundraiser with
                  your friends, family and online community. In no time, support
                  will start pouring in.
                </p>
              </div>
            </div>
            <div class="fundstep-item">
              <img
                src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117714/web/ic-withdraw.svg"
                class="fundstep-icon"
              />
              <div class="fundstep-text">
                <h3>Manage your fundraiser</h3>
                <p>
                  View your progress, add custodians, accept mobile money and
                  card donations and more.
                </p>
              </div>
            </div>
          </div>
          <div class="fundraiser-step-action">
            <a
              href="https://accounts.mchanga.africa/signup"
              class="button"
              style="margin-bottom: 1em"
              >Start a Fundraiser</a
            >
            <a href="/faq" class="button button-outline">Learn More</a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="fundraiser-step-slider">
            <div class="curve-background">
              <img
                src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117720/web/thm-shape-1.svg"
              />
            </div>
            <div class="phone-slider">
              <div class="phone-mockup">
                <img
                  src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/device-contour.svg"
                />
              </div>
              <div class="screen-carousel">
                <carousel autoplay="2000" :wrapAround="true" :items-to-show="1">
                  <slide v-for="v in stepsSlide" :key="v">
                    <img :src="v.src" alt="Slider" />
                  </slide>
                </carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stepsSlide: [
        {
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117718/web/screen-1.jpg",
        },
        {
          src: "https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117718/web/screen-2.jpg",
        },
        {
          src: require("@/assets/images/activation.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.fundraiser-step-action {
  display: block !important;
  text-align: center !important;
}
</style>
